import { createEffect, createEvent, sample } from "effector";

import {
  createApiErrorStatusStore,
  fromApi,
  getReservationSchedule,
  postReservationCollection,
} from "shared/api";
import { createOpenAbleState } from "shared/lib/effector-openable-state";

import { createOverrideForm } from "./form";
import { createOverrideFx } from "../api";

export const [$reservationAddModalOpened, reservationAddModalActions] =
  createOpenAbleState();

export const $serverErrorStatus = createApiErrorStatusStore(
  postReservationCollection
);

export const creatingReservationStarted = createEvent();

const SubmitFormFx = createOverrideFx;
export const newReservationCreated = SubmitFormFx.done;
export const $formSubmitting = SubmitFormFx.pending;

sample({
  clock: creatingReservationStarted,
  target: reservationAddModalActions.open,
});

sample({
  clock: newReservationCreated,
  target: reservationAddModalActions.close,
});

export const {
  form: newReservationForm,
  $price,
  $selectedDock,
  $bookingFee,
  $charterFee,
  $taxRate,
  $totalPrice,
  dateTimeModel,
  $availableSpacesList,
} = createOverrideForm({
  onSubmit: SubmitFormFx,
  getAvailableItemsFx: createEffect(fromApi(getReservationSchedule)),
  $isFormOpen: $reservationAddModalOpened,
});
