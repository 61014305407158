import styled from "styled-components";
import { useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";

import { ReservationSlotSpace } from "entities/docks/types";

import { $availableSpacesList } from "../model/edit-override.model";

export type SpaceSelectorProps = Omit<SelectProps<string>, "options"> & {
  valueSelector?(space: ReservationSlotSpace): any;
};

//TODO Refactor to reuse
export function SpaceSelectorComponent(props: SpaceSelectorProps): JSX.Element {
  const options = useStoreMap($availableSpacesList, (sections) => {
    return sections
      .flatMap((section) => section.spaces)
      .map((space) => ({
        label: space.name,
        value: props.valueSelector?.(space) ?? space.id,
      }));
  });

  return <Select {...props} options={options} />;
}

export const SpaceSelector = styled(SpaceSelectorComponent)``;

export const SpaceField = createFormField(SpaceSelector);
