import { ReactNode } from "react";
import styled from "styled-components";
import { Box, Collapse } from "@mui/material";

import {
  CheckBoxField,
  FtInputField,
  PriceField,
  PriceInput,
  TextAreaField,
} from "shared/ui/ecosystems/forms";
import { DockField } from "entities/docks";
import { TaxInput } from "shared/ui/ecosystems/forms/atoms/input";
import { mediaPhone } from "shared/ui/styles/media";
import { Dock } from "shared/api/types";
// import { SpaceField } from "./space-selector";
// import { SpaceField } from "./space-selector-edit";

export function OverrideFormFields(props: {
  selectedDock?: Dock;
  price: string;
  taxRate: number | null;
  bookingFee: string;
  charterFee: string;
  renderDateTimeSelector: () => ReactNode;
  renderSpaceSelector: () => ReactNode;
}): JSX.Element {
  const bookingFeeNumber = parseFloat(props.bookingFee);
  const charterFeeNumber = parseFloat(props.charterFee ?? "0");
  const totalPrice = parseFloat(props.price);

  return (
    <>
      <FormFields>
        <FtInputField name="loa" label="Enter LOA *" min={0} maxLength={3} />
        <FtInputField name="beam" label="Enter beam *" min={0} maxLength={3} />
        <CheckBoxField name="isCharter">Is this a charter?</CheckBoxField>
      </FormFields>

      <FormFields>
        <DockField name="dock" label="Select dock *" />

        {props.renderDateTimeSelector()}
      </FormFields>

      <FormFields>
        {props.renderSpaceSelector()}
        <IsOverride name="isSuperOverride">Super Override</IsOverride>
      </FormFields>

      <FormFields>
        <PriceField
          name="totalPrice"
          label="Before Tax *"
          min={0}
          maxLength={6}
        />
      </FormFields>

      <Tax>
        <Collapse in={Boolean(!!props.selectedDock && props.price)}>
          <FormFields>
            <TaxInput
              value={props.taxRate?.toFixed(2)}
              label="Tax Rate"
              disabled
            />
            <PriceInput
              value={bookingFeeNumber?.toFixed(2)}
              disabled
              label="Booking Fee"
            />
            {props.charterFee && (
              <PriceInput
                value={charterFeeNumber.toFixed(2)}
                disabled
                label="Charter Fee"
              />
            )}

            <PriceInput
              value={props.selectedDock && totalPrice.toFixed(2)}
              disabled
              label="Total Price"
            />
          </FormFields>
        </Collapse>
      </Tax>

      <Comment>
        <TextAreaField
          name="comment"
          label="Write a comment"
          maxLength={1000}
          minRows={3}
          maxRows={3}
        />
      </Comment>
    </>
  );
}

const IsCharter = styled(CheckBoxField)``;
const IsOverride = styled(CheckBoxField)`
  margin-top: 10px;

  ${mediaPhone} {
    margin-top: auto;
  }
`;

const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  ${mediaPhone} {
    grid-template-columns: 1fr;
    ${IsCharter} {
      grid-column: 1;
    }
  }

  &:not(:first-child) {
    margin-bottom: 10px;
  }
`;

const Comment = styled(Box)`
  margin-top: 10px;
  > .MuiFormControl-root {
    width: 100%;
  }
`;

const Tax = styled.div``;
