import { useUnit } from "effector-react";

import {
  FormError,
  FormModalTemplate,
  SubmitButton,
  SelectField,
  DateField,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { addReservationApiErrorsByStatuses } from "shared/api";

import {
  createDurationSelector,
  createDateSelector,
  createTimeSelector,
} from "entities/reservations";

import styled from "styled-components";

import {
  $formSubmitting,
  $price,
  $selectedDock,
  $serverErrorStatus,
  editReservationForm,
  $bookingFee,
  $charterFee,
  dateTimeModel,
  $reservationEditModalOpened,
  reservationEditModalActions,
} from "../model/edit-override.model";

import { OverrideFormFields } from "./form-fields";
import { SpaceField } from "./space-selector-edit";

export function EditManagerReservationModal(): JSX.Element {
  const [
    reservationAddModalOpened,
    isSubmitting,
    errorStatus,
    selectedDock,
    price,
    bookingFee,
    charterFee,
  ] = useUnit([
    $reservationEditModalOpened,
    $formSubmitting,
    $serverErrorStatus,
    $selectedDock,
    $price,
    $bookingFee,
    $charterFee,
  ]);

  const error =
    (errorStatus && addReservationApiErrorsByStatuses[errorStatus]) ??
    "Unknown error";

  const taxRate = selectedDock && parseFloat(selectedDock.taxRate);

  return (
    <FormModalTemplate
      form={editReservationForm}
      isOpened={reservationAddModalOpened}
      onClose={reservationEditModalActions.close}
      renderTitle={() => "Edit Override"}
      renderSubtitle={() => (
        <>
          A walk-in reservation or event? Enter the needed footage and period in
          this form.
        </>
      )}
      renderContent={() => (
        <FormContent>
          {errorStatus && <FormError>{error}</FormError>}
          <Form form={editReservationForm}>
            <OverrideFormFields
              renderDateTimeSelector={() => (
                <>
                  <DurationSelector
                    renderElement={(props) => (
                      <SelectField
                        label="Duration"
                        name="duration"
                        {...props}
                      />
                    )}
                  />

                  <DateSelector
                    renderElement={(props) => (
                      <DateField name="date" label="Date" {...props} />
                    )}
                  />
                  <TimeSelector
                    renderElement={(props) => (
                      <SelectField label="Time" name="time" {...props} />
                    )}
                  />
                </>
              )}
              renderSpaceSelector={() => (
                <SpaceField name="dockSpace" label="Space" />
              )}
              selectedDock={selectedDock}
              price={price}
              taxRate={taxRate}
              bookingFee={bookingFee}
              charterFee={charterFee}
            />
          </Form>
        </FormContent>
      )}
      renderActions={() => (
        <SubmitButton isSubmitting={isSubmitting}>Edit Override</SubmitButton>
      )}
    />
  );
}

const DurationSelector = createDurationSelector(dateTimeModel);
const DateSelector = createDateSelector(dateTimeModel);
const TimeSelector = createTimeSelector(dateTimeModel);

const FormContent = styled.div`
  ${FormError} {
    margin-bottom: 24px;
  }
`;
